import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'layouts/index'
import Seo from 'components/seo'
import global from '/static/images/platform/global_network_hero.webp'
import globalCard from '/static/images/platform/global_network_bar.webp'
import Cover from 'components/common/cover'
import WideCard from 'components/common/wideCard'
import UseCase from 'components/common/useCase'
import TabCover from 'components/common/tabCover'
import ContentCarousel from 'components/common/contentCarousel'
import {
  globalNetworkTraits,
  globalNetworkFeatures,
  globalNetworkTab
} from '/static/data/platform/global-network.static'

const GlobalNetwork = () => {
  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: `Mlytics Global Networks`,
            metaDescription: `With over 300 PoPs worldwide, Mlytics delivers fast, reliable, and scalable connections tailored to your needs through advanced Multi-networks solutions.`,
            metaUrl: 'www.mlytics.com/platform/global-network',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section
          style={{
            height: 'calc(100vh - 100px)',
            minHeight: '600px'
          }}
          className="px-9 md:px-[86px] mb-5 lg:max-h-[800px] base:absolute base:left-1/2 base:-translate-x-1/2 base:w-screen base:max-w-[1920px]"
        >
          <Cover
            smallTitle="Global Network"
            title="Unrivaled Visibility and Precision at Global Scale"
            imgSrc={global}
            imgAlt="Global Network Hero Picture"
            paragraphContent="Mlytics' lightning-fast global platform with precise AI-based routing empower you to have seamless application and digital assets delivery. This fusion of intelligence and speed revolutionizes user engagement, ensuring a captivating experience that leaves a lasting impression."
            actionLinkOne="/contact-us"
            actionTextOne="Learn more"
          />
        </section>
        <div className="hidden mb-5 lg:max-h-[800px] base:block base:h-[calc(100vh-100px)] base:min-h-[600px]"></div>
        <section className="py-[60px] px-9 md:px-[86px]">
          <div className="w-full flex flex-col gap-10">
            <WideCard imgSrc={globalCard} imgAlt="Global network wide card">
              <div className="py-[60px] px-4 md:px-10 w-full md:w-[60%] bg-experience-gradient">
                <div className="flex items-center flex-wrap gap-y-10">
                  {globalNetworkTraits.map((feature) => (
                    <div key={feature.id} className="flex flex-col items-center justify-center w-1/2 md:w-1/3">
                      <span className="block h2-block-title text-white text-center mb-2">{feature.figure}</span>
                      <span className="block p-content-text-mobile md:h4-content-text text-white text-center">
                        {feature.unit}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </WideCard>
          </div>
        </section>
        <section className="py-[60px] px-9 md:px-[86px] flex justify-center">
          <TabCover title="Global Edge Map" tabItems={globalNetworkTab} />
        </section>
        <section className="py-[60px] px-9 md:px-[86px]">
          <ContentCarousel listItem={globalNetworkFeatures} />
        </section>
        <section className="py-[60px]">
          <UseCase />
        </section>
      </React.Fragment>
    </Layout>
  )
}

export default GlobalNetwork

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
