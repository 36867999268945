import React from 'react'
import { Link } from 'gatsby'

import map from '/static/images/platform/global_network_map.webp'
import one from '/static/images/platform/global_network_feature_1.webp'
import two from '/static/images/platform/global_network_feature_2.webp'
import three from '/static/images/platform/global_network_feature_3.webp'

export const globalNetworkTraits = [
  {
    id: 'pop',
    figure: '214+',
    unit: 'Global PoP'
  },
  {
    id: 'wavers',
    figure: '300+',
    unit: 'Global wavers'
  },
  {
    id: 'countries',
    figure: '173',
    unit: 'Countries'
  },
  {
    id: 'chinaWavers',
    figure: '166',
    unit: 'Wavers in China'
  },
  {
    id: 'chinaCities',
    figure: '121',
    unit: 'Cities in China'
  }
]

export const globalNetworkTab = [
  {
    id: 'global',
    content: '',
    picture: map,
    coverTitle: '',
    coverContent: '',
    link: '',
    cta: ''
  }
]

export const globalNetworkFeatures = [
  {
    title: 'Access to the greatest networks',
    content:
      'There’s no single network that can satisfy all kinds of business needs, that’s why mlytics integrates multi networks and offers an automated, out-of-the-box platform to deploy, monitor, and manage all in one place.',
    imageSrc: one,
    imageAlt: 'Access to the greatest networks'
  },
  {
    title: 'No more single point of failure',
    content:
      'Switching within the same network might still impact availability and performance. From bottom up, Mlytics platform is designed to switch among all networks and all nodes, there’s always a way.',
    imageSrc: two,
    imageAlt: 'No more single point of failure',
    children: (
      <Link to="/contact-us" className="block h5-subtext-medium text-blue-100 underline my-3 w-fit">
        Chat with us.
      </Link>
    )
  },
  {
    title: 'AI-driven performance optimization',
    content: `One of the key differentiators of Mlytics' global network infrastructure is its use of AI algorithms to optimize performance. The platform collects millions of data points from both partner Networks and client websites, performing simulated "synthetic" data monitoring and running these data streams through AI decision engines.`,
    imageSrc: three,
    imageAlt: 'AI-driven performance optimization',
    children: (
      <Link to="/contact-us" className="block h5-subtext-medium text-blue-100 underline my-3 w-fit">
        Chat with us.
      </Link>
    )
  }
]
